
<template>
    <div>
       
        <b-row>
            <b-col cols="12" class="mt-3">
                <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
            </b-col>
        </b-row>
    </div>
</template>



<script>

export default {
    name: 'SliderSkeleton',

    }
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {path: '/error_page/:msg',name: 'Error_page',component: () => import('@/views/Auth/ErrorPage.vue')},
  //Auth
  {path: '/login',name: 'Login',component: () => import('@/views/Auth/Login.vue')},
  {path: '/register',name: 'Register',component: () => import('@/views/Auth/Register.vue')},
  {path: '/user',name: 'User',component: () => import('@/views/Auth/Index.vue')},
  {path: '/user_add',name: 'User_add',component: () => import('@/views/Auth/Add.vue')},
  {path: '/profile',name: 'User_edit',component: () => import('@/views/Auth/Edit.vue')},
  {path: '/user/:id/permissions',name: 'User_permissions',component: () => import('@/views/Auth/Permissions.vue')},

  {path: '/forget_password',name: 'Forget_password',component: () => import('@/views/Auth/Forget.vue')},
  {path: '/reset-password',name: 'Reset_password',component: () => import('@/views/Auth/Reset.vue')},
  //Info
  {path: '/info',name: 'Info',component: () => import('@/views/Info/Info.vue')},
  {path: '/about',name: 'About ',component: () => import('@/views/Info/About.vue')},
  {path: '/contact',name: 'Contact ',component: () => import('@/views/Info/Contact.vue')},
  //category
  {path: '/category',name: 'Category',component: () => import('@/views/Category/Index.vue')},
  {path: '/category/:slug',name: 'Category_show',component: () => import('@/views/Category/Show.vue')},
  {path: '/category_add',name: 'Category_add',component: () => import('@/views/Category/Add.vue')},
  {path: '/category/:id/edit',name: 'Category_edit',component: () => import('@/views/Category/Edit.vue')},
  //posts
  {path: '/post',name: 'Post',component: () => import('@/views/Post/Index.vue')},
  {path: '/post_add',name: 'Post_add',component: () => import('@/views/Post/Add.vue')},
  {path: '/post/:id/edit',name: 'Post_edit',component: () => import('@/views/Post/Edit.vue')},
  //competition
  {path: '/competition',name: 'Competition',component: () => import('@/views/Competition/Index.vue')},
  {path: '/competition_add',name: 'Competition_add',component: () => import('@/views/Competition/Add.vue')},
  {path: '/competition/:id/edit',name: 'Competition_edit',component: () => import('@/views/Competition/Edit.vue')},
  {path: '/competition/:slug',name: 'Competition_show',component: () => import('@/views/Competition/Show.vue')},
  //customer service
  {path: '/messages',name: 'Messages',component: () => import('@/views/CustomerService/Messages.vue')},
  {path: '/messages/:id/edit',name: 'Message_edit',component: () => import('@/views/CustomerService/Message.vue')},
  //comment
  {path: '/comments',name: 'Comments',component: () => import('@/views/Comment/Comments.vue')},
  {path: '/comments/:id/edit',name: 'Comment_edit',component: () => import('@/views/Comment/Comment.vue')},
  //competitor
  {path: '/competitors/:slug',name: 'Competitors',component: () => import('@/views/Competitor/Competitors.vue')},
  {path: '/competitors/:id/edit',name: 'Competitor_edit',component: () => import('@/views/Competitor/Competitor.vue')},
  {path: '/competitors_add/:id',name: 'Competitor_add',component: () => import('@/views/Competitor/Competitor_add.vue')},
  {path: '/competitor/:id',name: 'Competitor_show',component: () => import('@/views/Competitor/Show.vue')},
  {path: '/user_posts/:id',name: 'User_posts',component: () => import('@/views/Competitor/User_posts.vue')},
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
 // let token = store.state.token;
  let token =localStorage.getItem('token');
  let permissions =store.state.permissions;
 // console.log("router == " + token)
  /*if (to.name !== 'Login' && !token) next({ name: 'Login' })
  else next()*/
  //users
  if (to.name == 'User' && !token) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
  else next()
  if (to.name == 'User_add' && !token) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
  else next()
  if (to.name == 'User_edit' && !token) next({ name: 'Error_page', params: { msg: 'unauthorized' } })
  else next()

   //Messages
   if (to.name == 'Messages' && !permissions.some(function(o){return o["name"] == "messages edit";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
   else next()
   if (to.name == 'Message_edit' && !permissions.some(function(o){return o["name"] == "messages edit";})) next({ name: 'Error_page',params: { msg: 'unauthorized' }  })
   else next()


})

export default router

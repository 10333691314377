<template>
  <div >
    <SliderSkeleton v-if="!showContent" />
    <b-carousel v-if="showContent"
      id="carousel-1"
      v-model="slide"
      :interval="3000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide v-for="slider in sliders" :key="'slider'+slider.id"
        :img-src="$store.state.backendUrl+slider.image"
      ></b-carousel-slide>

    </b-carousel>

  
  </div>
</template>

<script>
import axios from 'axios'
import SliderSkeleton from '@/components/skeletons/SliderSkeleton.vue'
  export default {
      name:'Sliders',
    data() {
      return {
        slide: 0,
        sliding: null,
        path:this.$store.state.backendUrl+"api/ar/slider",
        sliders:null,
        showContent : false,
      }
    },
    components:{
SliderSkeleton
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      },
           getCreateData(){
           this.showContent =false;
           axios.get(this.path, {params:this.form,
            headers: {
          // 'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                this.sliders = response.data.success.sliders;
                this.showContent =true;
           
         
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } ,
 
    },
    mounted(){
     this.getCreateData();
    }
  }
</script>
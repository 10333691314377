<template>
  <div id="app">
    <ErrorAlert :msg="errorMgs" :visible="ShowError" />   
    <NavBar/>
<div class="page-content">
  <!--     
    <InfoAlert msg="" :visible="false" />
     <DoneAlert msg="" :visible="false" />
     <ErrorAlert msg="" :visible="false" />
     -->
   <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <router-view/>
    </div>
    <Footer/>
    
  </div>
</template>
<script>
import axios from 'axios'
// @ is an alias to /src
import NavBar from '@/components/layout/NavBar.vue'
import Footer from '@/components/layout/Footer.vue'
/*
import InfoAlert from '@/components/alerts/InfoAlert.vue'
import DoneAlert from '@/components/alerts/DoneAlert.vue'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
*/
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
export default {
  data() {
      return {
      Apppath:this.$store.state.backendUrl+"api/ar/info",

         }
    },
  components: {
    NavBar,Footer,ErrorAlert
    
  },
  methods: {
        getCreateData(){
           
           axios.get(this.Apppath, {params:this.form,
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                this.$store.state.info = response.data.success.info;
                this.$store.state.contact = response.data.success.contact;
           
         
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } 
  },
   mounted(){
     this.getCreateData();
        this.$i18n.locale = 'ar'
    }
}
</script>
<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
/*  color: #2c3e50;*/
}

/*#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}*/
</style>

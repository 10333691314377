import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/axios'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import cookie from './plugins/cookie'
import i18n from './i18n'
import "./assets/css/style.css"
import Vuelidate from 'vuelidate'
import store from './store'
import VueYoutube from 'vue-youtube'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueYoutube)
Vue.config.productionTip = false


new Vue({
  router,
  cookie,
  i18n,
  store,
  beforeCreate() { this.$store.commit('initialiseStore')},
  render: h => h(App)
}).$mount('#app')



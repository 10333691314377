
<template>
    <div >
      <b-container  class="mt-5 "  > 
   <b-row>
  <b-col cols="3">
    <b-skeleton-img></b-skeleton-img>
  </b-col>
  <b-col cols="3">
    <b-skeleton-img></b-skeleton-img>
  </b-col>
  <b-col cols="3">
    <b-skeleton-img></b-skeleton-img>
  </b-col>
  
  </b-row>
    </b-container>
    </div>
</template>



<script>

export default {
    name: 'PostsSkeleton',

    }
</script>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:"",
    name:"",
    permissions:{},
    agreedToPrivacy: true,
    //backendUrl:"http://127.0.0.1:8000/",
    backendUrl:"https://api.pwdgottalent.com/",
    baseUrl:"http://localhost:8081/",
    languages:{ar:'ar'},
    mainLanguage:'ar',
    info:[],
    contact:[],
    votes:[],
    comments:[]
  },
  mutations: {

    initialiseStore(state) {
         localStorage.setItem('agreedToPrivacy', true);
    //  if (localStorage.getItem('agreedToPrivacy')) {
         state.agreedToPrivacy = true;
         if(localStorage.getItem('token')){
          state.token = localStorage.getItem('token');
         }
         if(localStorage.getItem('name')){
          state.name = localStorage.getItem('name');
         }
         if(localStorage.getItem('permissions')){
          state.permissions = JSON.parse(localStorage.getItem('permissions'));
         }
         if(localStorage.getItem('comments')){
          state.comments = JSON.parse(localStorage.getItem('comments'));
         }
         if(localStorage.getItem('votes')){
          state.votes = JSON.parse(localStorage.getItem('votes'));
         }
     // }
      if(state.token == ""){
        return false;
      }
      return true;
    },

    agreePrivacyPolicy(state) {
      localStorage.setItem('agreedToPrivacy', true);
      state.agreedToPrivacy = true;
    },

    saveComments(state,post_id) {
      state.comments.push(post_id);
      if( state.agreedToPrivacy == true){
      localStorage.setItem('comments', JSON.stringify(state.comments));
      }
      
    },

    saveVotes(state,post_id) {
      state.votes.push(post_id);
      if( state.agreedToPrivacy == true){
      localStorage.setItem('votes', JSON.stringify(state.votes));
      }
      
    },

    saveLogin(state,LoginData){
      state.token =LoginData.token;
      state.name =LoginData.name;
      state.permissions =LoginData.permissions;

      if (localStorage.getItem('agreedToPrivacy')) {
        localStorage.setItem('token', LoginData.token);
        localStorage.setItem('name', LoginData.name);
        localStorage.setItem('permissions', LoginData.permissions);
      }
    },

    Logout(state){
      state.token ="";
      state.name ="";
      state.permissions ={};

      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('permissions');
    }




    

  },
  actions: {
  },
  modules: {
  }
})

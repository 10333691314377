<template>
    <div> 
  <b-navbar toggleable="lg" type="dark" variant="primary">
  
    <b-navbar-brand to="/"><img class="main-logo" :src="$store.state.backendUrl+$store.state.info.logo" alt=""></b-navbar-brand>
    
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/">{{$t('Home')}}</b-nav-item>
        <b-nav-item to="/category">{{$t('Competitions')}}</b-nav-item>
        <b-nav-item to="/about">{{$t('About')}}</b-nav-item>
        <b-nav-item to="/contact">{{$t('Contact')}}</b-nav-item>
        <b-nav-item v-if="!$store.state.name" to="/login">{{$t('Login')}}</b-nav-item>
        <b-nav-item v-if="!$store.state.name" to="/register">{{$t('Register')}}</b-nav-item>
      </b-navbar-nav> 

      <!-- Right aligned nav items -->
    <b-navbar-nav class="mr-auto">
 
     <!--     <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
        </b-nav-form>

        <b-nav-item-dropdown text="Lang" right>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>
-->
        <b-nav-item-dropdown v-if="$store.state.name" right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>{{$store.state.name}}</em>
          </template>
         <b-dropdown-item class="text-right" to="/profile" >{{$t('Profile')}}</b-dropdown-item>
          <b-dropdown-item class="text-right" @click="logout">{{$t('Logout')}}</b-dropdown-item>
        </b-nav-item-dropdown>


              <b-nav-item v-f="$store.state.backendUrl+$store.state.contact.facebook" :href="$store.state.backendUrl+$store.state.contact.facebook" target="_blank"><b-icon icon="facebook"  aria-hidden="true"></b-icon></b-nav-item>
              <b-nav-item v-f="$store.state.backendUrl+$store.state.contact.twitter" :href="$store.state.backendUrl+$store.state.contact.twitter" target="_blank"><b-icon icon="twitter"  aria-hidden="true"></b-icon></b-nav-item>
              <b-nav-item v-f="$store.state.backendUrl+$store.state.contact.linkedin" :href="$store.state.backendUrl+$store.state.contact.linkedin" target="_blank"><b-icon icon="linkedin"  aria-hidden="true"></b-icon></b-nav-item>
              <b-nav-item v-f="$store.state.backendUrl+$store.state.contact.instagram" :href="$store.state.backendUrl+$store.state.contact.instagram" target="_blank"><b-icon icon="instagram"  aria-hidden="true"></b-icon></b-nav-item>
              <b-nav-item v-f="$store.state.backendUrl+$store.state.contact.youtube" :href="$store.state.backendUrl+$store.state.contact.youtube" target="_blank"><b-icon icon="youtube"  aria-hidden="true"></b-icon></b-nav-item>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
export default {
    name: 'NavBar',

    methods:{
      logout(){
        this.$store.commit('Logout');
        this.$router.push('login')
      }
    }
}
</script>
<template>
  <div>
   <PostsSkeleton v-if="!showContent" />
    <b-container v-if="showContent" class="mt-5 "  >

    <h2>المسابقات</h2>
     <b-row >
  <b-col  cols="12" lg="4" xl="4" md="6"  v-for="post in posts" :key="'categossssry'+ post.id" >
  <b-card 
    :title="post.name"
    :img-src="$store.state.backendUrl+post.thumbnailxl"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 20rem;"
    class="mb-2"
  >
    

    <b-button :to="{ name: 'Competition_show',params: { slug: post.slug }  }" variant="primary">المزيد</b-button>
 </b-card>


    </b-col>
    </b-row>




  </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import PostsSkeleton from '@/components/skeletons/PostsSkeleton.vue'
  export default {
      name:'Competitions',
    data() {
      return {
        showContent: false,
        path:this.$store.state.backendUrl+"api/ar/competitions",
        posts:[],
      }
    },
    components:{
      PostsSkeleton
    }
    ,
    methods: {
  
           getCreateData(){
           this.showContent =false;
           axios.get(this.path, {params:this.form,
            headers: {
          // 'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                this.posts = response.data.success.posts;
               this.showContent =true;
         
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } ,
 
    },
    mounted(){
     this.getCreateData();
    }
  }
</script>
<template>
<div>
<Sliders/>
<Competitions/>
</div>
</template>

<script>
import Sliders from '@/components/home/Sliders.vue'
import Competitions from '@/components/home/Competitions.vue'
export default {
    data() {
      return {
        meta:{
           title:"",
           description:"",
           favicon :""
        },
        
      }
    },
    components:{
        Sliders,Competitions
        
    },
    metaInfo() {
      return {
      title: this.meta.title ,
      meta:[{ name: 'description', content: this.meta.description }] ,
      link:[{ rel: 'icon', href: this.meta.favicon }] 
  
    }
     },
     mounted() {
     this.meta.title = this.$store.state.info.name;
     this.meta.description=this.$store.state.info.short_decription;
     this.meta.favicon =this.$store.state.backendUrl+'/'+this.$store.state.info.favicon ;
     }

}

</script>